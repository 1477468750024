function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _componentsEmoji = require('./components/emoji');

var _componentsEmoji2 = _interopRequireDefault(_componentsEmoji);

$(function () {
  $.each($('.emojify'), function (_, content) {
    var $content = $(content);
    $content.html((0, _componentsEmoji2['default'])($content.html()));
  });

  $('.video-player video').on('click', function (e) {
    if (e.target.paused) {
      e.target.play();
    } else {
      e.target.pause();
    }
  });

  $('.media-spoiler').on('click', function (e) {
    $(e.target).hide();
  });

  $('.webapp-btn').on('click', function (e) {
    if (e.button === 0) {
      e.preventDefault();
      window.location.href = $(e.target).attr('href');
    }
  });

  $('.status__content__spoiler-link').on('click', function (e) {
    e.preventDefault();
    var contentEl = $(e.target).parent().parent().find('div');

    if (contentEl.is(':visible')) {
      contentEl.hide();
      $(e.target).parent().attr('style', 'margin-bottom: 0');
    } else {
      contentEl.show();
      $(e.target).parent().attr('style', null);
    }
  });
});